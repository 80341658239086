<template>
    <div :class="['flex flex-col', componentClass]">
        <a :href="route('home')" :class="['text-xs sm:text-2xl font-spaceBoard', textClass]">
            <img src="/images/logo.svg" alt="LevelUp Desk" class="w-full">
        </a>
    </div>
</template>

<script setup>
import {computed} from 'vue';

const props = defineProps({
    variant: {
        type: String,
        default: 'default',
    },
});

const componentClass = computed(() => (props.variant === 'white' ? 'text-white' : 'text-gray-900'));
const textClass = computed(() => (props.variant === 'white' ? 'text-white' : 'text-gray-900'));
</script>

<style scoped>
.text-white {
    color: #ffffff;
}
</style>
